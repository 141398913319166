import React from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { slugify } from "../util/utilityFunctions"
// Components
import Layout from "../components/layout"
import Image from "../components/image"
import InnerWrapper from "../components/innerWrapper"
import GA from "../components/ga"
// import SidebarCart from "../components/sidebarCart"

// Styles
import styles from "./scss/product.module.scss"
const productPage = (props) => {
  let related = props.data.allContentfulProduct.edges.sort(function (a, b) {
    let textA = a.node.name.toUpperCase()
    let textB = b.node.name.toUpperCase()
    return textA.localeCompare(textB)
  })

  return (
    <>
      <GA />
      <Layout>
        {/* <SidebarCart /> */}
        <section className={`section ${styles.product}`}>
          <InnerWrapper width={800}>
            <div className={styles.breadCrumb}>
              <Link to="/products" state={{ category: "" }}>
                ← Back to Search
              </Link>
            </div>
            <h1>{props.pageContext.name}</h1>
            <Image image={props.pageContext.image} />
            {documentToReactComponents(props.pageContext.desc.json)}

            <h4>Related Products</h4>
            <ul>
              {related
                .filter((item) => item.node.name !== props.pageContext.name)
                .map(function (item, index) {
                  return (
                    <li key={index}>
                      <Link
                        to={`/products/${slugify(
                          item.node.category.name
                        )}/${slugify(item.node.subcategory.name)}/${slugify(
                          item.node.name
                        )}`}
                      >
                        {item.node.name}
                      </Link>
                    </li>
                  )
                })}
            </ul>
          </InnerWrapper>
        </section>
      </Layout>
    </>
  )
}

export default productPage

export const query = graphql`
  query ($subcategory: String!) {
    allContentfulProduct(
      filter: { subcategory: { name: { eq: $subcategory } } }
    ) {
      edges {
        node {
          name
          category {
            name
          }
          subcategory {
            name
          }
        }
      }
    }
  }
`
